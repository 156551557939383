$(function () {
	cookies = {
		isActive: 'false',

		cookiesBar: function (elementContainer) {
			var self = this;
			self.element = $('<div class="cookie__bar"></div>)');
			$(self.element).hide().appendTo(elementContainer).fadeIn(1500);
			//regex
			hexaColor = /(?:[0-9a-f]{3}){1,2}$/i;
			digits = /\d+/;
			//data-attributes
			self.mainColor = elementContainer.attr('data-cookies-color-main').match(hexaColor);
			self.alternativeColor = elementContainer.attr('data-cookies-color-secundary').match(hexaColor);
			self.borderRadius = elementContainer.attr('data-cookies-border-radius').match(digits);
			self.msgTextColor = elementContainer.attr('data-cookies-msg-color');
			self.msgTextColor === 'white' ? self.element.addClass('text--white') : self.element.addClass('text--black');
			// if cookie eelement exists then set text data attributes in body
			if (self.element) {
				self.text = elementContainer.attr('data-cookies-text') ? elementContainer.attr('data-cookies-text') : 'By continuing to browse or by clicking "Accept All Cookies", you agree to the storing of first and third-party cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts';
				self.target = elementContainer.attr('data-cookies-target') ? elementContainer.attr('data-cookies-target') : '_blank';
				self.policyText = elementContainer.attr('data-cookies-policy-text') ? elementContainer.attr('data-cookies-policy-text') : '';
				self.policyLink = elementContainer.attr('data-cookies-policy-link') ? elementContainer.attr('data-cookies-policy-link') : '';
				self.hasLink = self.policyLink ? '<a href="' + self.policyLink + '" target="' + self.target + '" class="cookie__bar-link">' + self.policyText + '</a>' : '';
				self.settingsBtn = elementContainer.attr('data-cookies-btn-settings') ? elementContainer.attr('data-cookies-btn-settings') : 'Cookie Settings';
				self.acceptBtn = elementContainer.attr('data-cookies-btn-accept') ? elementContainer.attr('data-cookies-btn-accept') : 'Accept all Cookies';
				self.position = elementContainer.attr('data-cookies-position');
				self.position === 'top' ? self.element.addClass('top') : self.element.addClass('bottom');

			}
			self.element.html('<div class="cookie__container"><span class="cookie__bar-text">' + '<span>' + self.text + '</span>' + self.hasLink + '</span>' + '<a class="cookie__bar-button btn--settings cookie__btn" href="javascript:;">' + self.settingsBtn + '</a>' + '<a class="cookie__bar-button btn--allow cookie__btn">' + self.acceptBtn + '</a></div>')

			// CSS 
			self.element.css('background-color', '#' + self.alternativeColor)

			self.element.find('.cookie__btn').css({
				'border-radius': self.borderRadius + 'px',
				'border-color': '#' + self.mainColor
			})

			self.element.find('.btn--allow').css('background-color', '#' + self.mainColor)

			self.element.find('.cookie__bar-link, .btn--settings').css('color', '#' + self.mainColor)
		},

		cookiesGenerate: function (elementContainer) {
			var self = this;
			self.gaID = elementContainer.attr('data-cookies-gaID');

			//FIX Polyfill startsWith for IE  -  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
			if (!String.prototype.startsWith) {
				Object.defineProperty(String.prototype, 'startsWith', {
					value: function(search, pos) {
						pos = !pos || pos < 0 ? 0 : +pos;
						return this.substring(pos, pos + search.length) === search;
					}
				});
			}

			if(!self.gaID.startsWith("UA-")){
				self.gaID = "UA-"+self.gaID;
				console.log(self.gaID);
			}
		

			self.gtmID = elementContainer.attr('data-cookies-gtmID');
			self.hjID = elementContainer.attr('data-cookies-hjID');
			self.fbqID = elementContainer.attr('data-cookies-fbqID');
			//Create and Use Cookies
			setGetCookies = {
				setCookie: function (name, value, days) {
					var expires = "";
					if (days) {
						var date = new Date();
						date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
						expires = date.toUTCString();
					}
					document.cookie = name + "=" + (value || "") + "; expires=" + expires + "; path=/";
				},
				getCookie: function (name) {
					var nameEQ = name + "=";
					var ca = document.cookie.split(';');
					for (var i = 0; i < ca.length; i++) {
						var c = ca[i];
						while (c.charAt(0) == ' ') c = c.substring(1, c.length);
						if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
					}
					return null;
				},
				deleteAllcokies: function () {
					var cookies = document.cookie.split("; ");
					for (var c = 0; c < cookies.length; c++) {
						var d = window.location.hostname.split(".");
						while (d.length > 0) {
							var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
							var p = location.pathname.split('/');
							document.cookie = cookieBase + '/';
							while (p.length > 0) {
								document.cookie = cookieBase + p.join('/');
								p.pop();
							};
							d.shift();
						}
					}
				}
			};
			//optionalCookies
			optionalCookies = [{
					// 1
					optOut: function () {
						if (self.gaID) {
							console.log('Optout1');
							window['ga-disable-UA-' + self.gaID] = true;
						}
					},
					optIn: function () {
						if (setGetCookies.getCookie('accepted-optional') !== "false") {
							if (self.gaID) {
								console.log('Optin1');
								window['ga-disable-UA-' + self.gaID] = false;
								(function (i, s, o, g, r, a, m) {
									i['GoogleAnalyticsObject'] = r;
									i[r] = i[r] || function () {
										(i[r].q = i[r].q || []).push(arguments)
									}, i[r].l = 1 * new Date();
									a = s.createElement(o),
										m = s.getElementsByTagName(o)[0];
									a.async = 1;
									a.src = g;
									m.parentNode.insertBefore(a, m)
								})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
								ga('create', self.gaID, 'auto');
								ga('set', 'anonymizeIp', true);
								ga('set', 'forceSSL', true);
								ga('send', 'pageview');
							}
						}
					},

				},
				//2
				{
					optOut: function () {
						if (self.gtmID) {
							console.log('Optout2');
						}
					},
					optIn: function () {
						if (setGetCookies.getCookie('accepted-optional') !== "false") {
							if (self.gtmID) {
								console.log('Optin2');
								(function (w, d, s, l, i) {
									w[l] = w[l] || [];
									w[l].push({
										'gtm.start': new Date().getTime(),
										event: 'gtm.js'
									});
									var f = d.getElementsByTagName(s)[0],
										j = d.createElement(s),
										dl = l != 'dataLayer' ? '&l=' + l : '';
									j.async = true;
									j.src =
										'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
									f.parentNode.insertBefore(j, f);
								})(window, document, 'script', 'dataLayer', self.gtmID);
							}
						}
					}
				},
				//3
				{
					optOut: function () {
						if (self.hjID) {
							console.log('Optout3');
						}

					},
					optIn: function () {
						if (setGetCookies.getCookie('accepted-optional') !== "false") {
							if (self.hjID) {
								console.log('Optin3');
								(function (h, o, t, j, a, r) {
									h.hj = h.hj || function () {
										(h.hj.q = h.hj.q || []).push(arguments)
									};
									h._hjSettings = {
										hjid: self.hjID,
										hjsv: 5
									};
									a = o.getElementsByTagName('head')[0];
									r = o.createElement('script');
									r.async = 1;
									r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
									a.appendChild(r);
								})(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
							}
						}
					}
				},
				//4
				{
					optOut: function () {
						if (self.fbqID) {
							console.log('Optout4');
							setGetCookies.getCookie('accepted-optional') === 'false' ? fbq('init', '') : ''
						}

					},
					optIn: function () {
						if (setGetCookies.getCookie('accepted-optional') !== "false") {
							if (self.fbqID) {
								console.log('Optin4');
								! function (f, b, e, v, n, t, s) {
									if (f.fbq) return;
									n = f.fbq = function () {
										n.callMethod ?
											n.callMethod.apply(n, arguments) : n.queue.push(arguments)
									};
									if (!f._fbq) f._fbq = n;
									n.push = n;
									n.loaded = !0;
									n.version = '2.0';
									n.queue = [];
									t = b.createElement(e);
									t.async = !0;
									t.src = v;
									s = b.getElementsByTagName(e)[0];
									s.parentNode.insertBefore(t, s)
								}(window, document, 'script',
									'https://connect.facebook.net/en_US/fbevents.js');
								fbq('init', self.fbqID);
								fbq('track', "PageView");
							}
						}
					}
				}
			];
		},

		cookiesModal: function (elementContainer) {
			var self = this;
			//elements			
			self.modal = $('<div class="cookie__modal"></div>');
			//content modal
			self.title = elementContainer.attr('data-cookies-modal-title') ? elementContainer.attr('data-cookies-modal-title') : 'Privacy Preference Center'
			self.privacy = elementContainer.attr('data-cookies-tab-privacy') ? elementContainer.attr('data-cookies-tab-privacy') : 'Your Privacy';
			self.privacyText = elementContainer.attr('data-cookies-tab-privacy-text') ? elementContainer.attr('data-cookies-tab-privacy-text') : 'When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences, your device or used to make the site work as you expect it to. The information does not usually identify you directly, but it can give you a more personalized web experience. You can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, you should know that blocking some types of cookies may impact your experience on the site and the services we are able to offer.';
			self.mandatory = elementContainer.attr('data-cookies-tab-mandatory') ? elementContainer.attr('data-cookies-tab-mandatory') : 'Strictly Necessary Cookies';
			self.mandatoryLabel = elementContainer.attr('data-cookies-tab-mandatory-label') ? elementContainer.attr('data-cookies-tab-mandatory-label') : 'Always Active';
			self.mandatoryText = elementContainer.attr('data-cookies-tab-mandatory-text') ? elementContainer.attr('data-cookies-tab-mandatory-text') : 'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site may not work then.';
			self.optional = elementContainer.attr('data-cookies-tab-optional') ? elementContainer.attr('data-cookies-tab-optional') : 'Performance and Targeting Cookies';
			self.optionalLabel = elementContainer.attr('data-cookies-tab-optional-label') ? elementContainer.attr('data-cookies-tab-optional-label') : 'Active';
			self.optionalText = elementContainer.attr('data-cookies-tab-optional-text') ? elementContainer.attr('data-cookies-tab-optional-text') : 'Performance Cookies: These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.<br/>Targetting Cookies: These cookies are set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites. They work by uniquely identifying your browser and device. If you do not allow these cookies, you will not experience our targeted advertising across different websites. ';
			self.save = elementContainer.attr('data-cookies-btn-save') ? elementContainer.attr('data-cookies-btn-save') : 'Save Settings';
			self.logo = elementContainer.attr('data-cookies-logo') ? elementContainer.attr('data-cookies-logo') : '';

			self.modal.html('<div class="cookie__modal-container"> <div class="cookie__modal-header"> <div class="cookie__modal-close"> <div class="cookie__btn-close">X</div></div><div class="cookie__modal-logo"><img src="' + self.logo + '" alt="logo"/></div><div class="cookie__modal-title">' + self.title + '</div></div><div class="cookie__modal-body"> <div class="cookie__modal-tabs"> <ul> <li data-tab="cookies-privacy">' + self.privacy + '</li><li data-tab="cookies-mandatory">' + self.mandatory + '</li><li data-tab="cookies-optional">' + self.optional + '</li></ul> <div class="cookie__modal-tabs-content cookie__current" id="cookies-privacy"> <div class="cookie__modal-title">' + self.title + '</div><div class="cookie__modal-tabs-content-title"> <span>' + self.privacy + '</span></div><div class="cookie__modal-tabs-content-text">' + self.privacyText + '</div></div><div class="cookie__modal-tabs-content" id="cookies-mandatory"> <div class="cookie__modal-title">' + self.title + '</div><div class="cookie__modal-tabs-content-title"> <span>' + self.mandatory + '</span><label>' + self.mandatoryLabel + '</label></div><div class="cookie__modal-tabs-content-text">' + self.mandatoryText + '</div></div><div class="cookie__modal-tabs-content" id="cookies-optional"> <div class="cookie__modal-title">' + self.title + '</div><div class="cookie__modal-tabs-content-title"> <span>' + self.optional + '</span><input id="cookie__checkbox" type="checkbox"/><label for="cookie__checkbox"> <span>' + self.optionalLabel + '</span></label></div><div class="cookie__modal-tabs-content-text">' + self.optionalText + '</div></div></div></div><div class="cookie__modal-footer"> <div class="cookie__btn btn--save">' + self.save + '</div></div></div>');
			$(self.modal).appendTo('body')
			self.container = self.modal.find('.cookie__modal-container');

			//Default Settings
			$(window).on('load', function () {
				self.container.find('label[for="cookie__checkbox"]').click();
				if (self.container.find('#cookie__checkbox').prop('checked')) {
					for (i = 0; i < optionalCookies.length; i++)
						optionalCookies[i].optIn();
				}
				if (setGetCookies.getCookie('accepted-optional') === 'false') {
					self.container.find('#cookie__checkbox').prop('checked', false)
					for (i = 0; i < optionalCookies.length; i++)
						optionalCookies[i].optOut();
						setGetCookies.deleteAllcokies();
				}
			})
			// set background color in container to fix a bug that happens sometimes
			self.container.css('background-color', '#' + self.mainColor);

			// set border radius to modal container
			self.borderRadius >= 15 ? self.container.css('border-radius', '15px') : self.container.css('border-radius', self.borderRadius + 'px');

			//modal  CSS
			self.container.find('.cookie__modal-header, .cookie__modal-footer').css('background-color', '#' + self.mainColor)

			self.container.find('.cookie__btn,.cookie__btn-close').css({
				'border-radius': self.borderRadius + 'px',
				'color': '#' + self.mainColor
			})
			self.borderRadius > 1 ? self.container.find('.cookie__btn').css('background-clip', 'padding-box') : '';

			self.borderRadius > 1 ? self.container.find('label[for="cookie__checkbox"]').css('border-radius', self.borderRadius + 'px') : '';
		},

		cookiesEvents: function () {
			var self = this;
			//Close Modal
			self.container.find('.cookie__btn-close').click(function () {
				self.modal.removeClass('active');
				$('body').removeAttr('style')
			})

			//Open Modal
			self.element.find('.btn--settings').click(function () {
				self.modal.addClass('active');
				$('body').css('overflow', ' hidden');
				$('.cookie__modal-tabs > ul li,.cookie__modal-tabs-content').removeClass('cookie__current');
				$('.cookie__modal-tabs > ul li').first().addClass('cookie__current');
				$('.cookie__modal-tabs-content').first().addClass('cookie__current')
				setGetCookies.getCookie('accepted-optional') === 'true' && self.container.find('#cookie__checkbox').prop('checked', false) ? self.container.find('label[for="cookie__checkbox"]').click() : '';
			})

			// save settings in modal
			self.container.find('.btn--save').click(function () {
				if (self.container.find('#cookie__checkbox').prop('checked')) {
					for (i = 0; i < optionalCookies.length; i++)
						optionalCookies[i].optIn();
				} else {
					for (i = 0; i < optionalCookies.length; i++)
						optionalCookies[i].optOut();
						setGetCookies.deleteAllcokies();
				}

				setGetCookies.setCookie('accepted-optional', self.isActive, 0);
				self.modal.removeClass('active');
				$('body').removeAttr('style')
				self.element.fadeOut(100);
			})

			// accept all cookies in button bar
			self.element.find('.btn--allow').click(function () {
				for (i = 0; i < optionalCookies.length; i++) {
					optionalCookies[i].optIn();
					self.isActive = 'true';
				}
				setGetCookies.setCookie('accepted-all', self.isActive, 365)
				self.element.fadeOut(500);
			})


			//check for change in checkebox check/uncheck
			self.container.find('#cookie__checkbox').change(function () {

				if ($(this).prop('checked')) {
					//checkbox CSS 
					self.container.find('label[for="cookie__checkbox"]').css({
						'background-color': '#' + self.mainColor,
						'color': '#' + self.mainColor,
						'border-radius': self.borderRadius + 'px'
					})


					for (i = 0; i < optionalCookies.length; i++)
						self.isActive = 'true';
				} else {
					self.container.find('label[for="cookie__checkbox"]').css({
						'background-color': '',
						'color': ''
					})
					for (i = 0; i < optionalCookies.length; i++)
						self.isActive = 'false';
				}
			})

			// check if cookie is accepted to disable cookie bar
			if (setGetCookies.getCookie('accepted-all') || setGetCookies.getCookie('accepted-optional')) {
				self.element.remove();
			}

		},
		// tabs
		cookiesTabs: function () {
			var self = this;
			self.container.find('.cookie__modal-tabs > ul li').click(function () {
				var tabId = $(this).attr('data-tab');
				$('.cookie__modal-tabs > ul li,.cookie__modal-tabs-content').removeClass('cookie__current');
				$(this).addClass('cookie__current');
				$("#" + tabId).addClass('cookie__current');
			})
		},
		// init
		init: function (elementSelector) {
			targetElement = $(elementSelector);
			cookies.cookiesBar(targetElement);
			cookies.cookiesGenerate(targetElement);
			cookies.cookiesModal(targetElement);
			cookies.cookiesTabs(targetElement);
			cookies.cookiesEvents();
		}
	}
	// init
	cookies.init('.js-fever-cookies');
})